import CryptoJS from 'crypto-js'

const secretKey = 'aa-ddert-ffkkd13654-djrhrfff^paa**'

// Encrypt user credentials before storing in localStorage
function encryptCredentials(username, password) {
  const data = JSON.stringify({ username, password })
  const encrypted = CryptoJS.AES.encrypt(data, secretKey)
  localStorage.setItem('credentials', encrypted.toString())
}

// Decrypt user credentials when retrieving from localStorage
function decryptCredentials() {
  const encrypted = localStorage.getItem('credentials')
  if (encrypted) {
    const bytes = CryptoJS.AES.decrypt(encrypted, secretKey)
    const data = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(data)
  }
  return null
}


export default {
  encryptCredentials,
  decryptCredentials
}
