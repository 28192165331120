/* MIVOCO SERVICES PLUGIN
  Init all required services / registration
*/

export default async ({ app, router, store }) => {
  store.dispatch('mivoco/loadAllLocalizations')

  // Get ALL DEVICES / DRIVERS
  // store.dispatch('mivoco/loadAllDevices')
  // Get ALL MVAS
  // store.dispatch('mivoco/loadAllMVAs')

  // Get ALL CALLS
  // store.dispatch('mivoco/loadAllCalls').catch(error => {
  //   console.log('error: ', error)
  // })

  console.debug('INIT')

  /* app.mixin({
    beforeCreate() {
      console.log(this.$options)
      const options = this.$options;
      if (options.mivocoService) {
        this.$mivocoService = options.mivocoService;
      } else if (options.parent && options.parent.$mivocoService) {
        this.$mivocoService = options.parent.$mivocoService;
      }
    },
  }); */
}

// export { notifService }
