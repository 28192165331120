export default {
  main_menu: {
    infrastructure_metas: { name: 'Démonstrateur en ligne' },
    dashboard: { label: 'Dashboard', caption: 'MVA browsing' },
    mva_install: { label: 'New MVA', caption: 'Install Wizard for a new MVA' },
    mctrl_install: { label: 'New Device', caption: 'Install new Device on a given MDA' },
    settings: { label: 'Settings', caption: 'Application settings and preferences' },
    notifications: { label: 'Notifications', caption: 'Browse all MVAs notifications' },
    remote: { label: 'Remote', caption: 'Command devices using remote' }
  },
  mva: {
    title:'Vocal Assistant',
    detail: {
      device_list: 'Devices List',
      new_device: 'New Device'
    },
    filters: {
      connected: 'Connected',
      disconnected: 'Disconnected',
      configurated: 'Configurated',
      notconfigurated: 'Not Configurated',
      mute: 'Muted',
    }
  },
  calls: {
    list: 'List',
    map: 'Map',
    table: {
      call_created: 'Call issued',
      area_name: 'Area name',
      call_handled: 'Call handled'
    },
    filters: {
      emergency: 'Emergency calls',
      normal: 'Normal calls',
      handled: 'Calls handled'
    },
    detail: {
      emergency: 'Emergency call',
      normal: 'Normal call',
      handled: 'Call handled'
    },
    pagination: {
      previous: 'previous',
      next: 'next'
    }
  },
  callLog: {
    title:'Calls History'
  }
}
