
export function allmvas(state) {
  return state.mvas ? state.mvas : []
}
export function allGroupedMvas(state) {
  return state.groupedMvas ? state.groupedMvas : []
}

export function alllocalizations(state) {
  return state.localizations ? state.localizations : []
}

export function allsectors(state) {
  let sectors = []
  state.localizations.forEach(elem => {
    if (sectors.findIndex(e => e?.id === elem.id_sector) == -1 && elem.sector !== undefined)
    sectors.push(elem.sector)
  })

  return state.localizations ? sectors: []
}

export function allcalls(state) {
  return state.calls ? state.calls : []
}

export function toBeConfigMvas(state) {
  let toBeConfigMvas = []
  if (state.mvas) {
    toBeConfigMvas = state.mvas.filter((elem) => elem.id_status === 3)
  } 
  return toBeConfigMvas
}

export function installedMvas(state) {
  let toBeConfigMvas = []
  if (state.mvas) {
    toBeConfigMvas = state.mvas.filter((elem) => elem.id_status !== 3)
  } 
  return toBeConfigMvas
}

export function alldevices(state) {
  return state.devices ? state.devices : []
}


export function alldevicedrivers(state) {
  return state.devicedrivers ? state.devicedrivers : []
}

export function preregistredWifis (state) {
  return JSON.parse(localStorage.getItem('PreregistredNets'))
}
