import { defineComponent } from 'vue'

export default {
    status_text_color(id_status) {
      // `this` points to the vm instance
      switch (id_status) {
        case 1: return "text-green-5"
          break;
        case 2: return "text-red-5"
          break;
        case 3: return "text-grey-5"
          break;
        case 4: return "text-orange-5"
          break;
      }
    return "text-grey-5"
  },
   status_bg_color(id_status) {
    // `this` points to the vm instance
    switch (id_status) {
      case 1: return "bg-green-5"
        break;
      case 2: return "bg-red-5"
        break;
      case 3: return "bg-grey-5"
        break;
      case 4: return "bg-orange-5"
        break;
    }
     return "grey-5"
  }
  ,
  status_color(id_status) {
    // `this` points to the vm instance
    switch (id_status) {
      case 1: return "green-5"
        break;
      case 2: return "red-5"
        break;
      case 3: return "grey-5"
        break;
      case 4: return "orange-5"
        break;
    }
    return "grey-5"
  }
  ,
   status_name(id_status) {
    switch (id_status) {
      case 1: return "on"
        break;
      case 2: return "off"
        break;
      case 3: return "unknown"
        break;
      case 4: return "configuration"
        break;
    }
     return "unknown"
  },
  status_call_icons(id_status) {
    switch (id_status) {
      case 1:
        return 'o_info'
        break;
      case 2:
        return 'phone_in_talk'
        break;
      case 3:
        return 'call'
        break;
      case 4:
        return 'call_end'
        break;
      case 5:
        return 'cancel'
        break;
    }
  },
  status_call_color(id_status) {
    switch (id_status) {
      case 1:
        return 'primary' //why not ring Colume ?
        break;
      case 2:
        return 'primary'
        break;
      case 3:
        return 'primary'
        break;
      case 4:
        return 'green-5'
        break;
      case 5:
        return 't-grey-3'
        break;
    }
  },
  status_call(id_status) {
    switch (id_status) {
      case 1:
        return 'En cours'
        break;
      case 2:
        return 'En communication'
        break;
      case 3:
        return 'Pris en charge'
        break;
      case 4:
        return 'Clôturé'
        break;
      case 5:
        return 'Annulé'
        break;
    }
  },
  isBiggerThanSm (quasar) {
    return !(quasar.screen.xs || quasar.screen.sm)
  },
  isSmallThanlg (quasar) {
    return quasar.screen.lt.lg
  },
  priority_colors: ['$negative', '$red', '$orange'],
  priority_icons: ['o_info', 'o_handyman', 'o_personal_injury', 'o_medical_services', 'o_gpp_maybe'],
  sectors: [{ id: 1, name: "Etage 1" }, { id: 2, name: "Etage 2" }, { id: 3, name: "Etage 3" }],
  mvaStatusIcon: ['', 'o_check_circle', 'o_power_off', 'o_error_outline'],
  mvaStatusTextClass: ['', 'text-green-5', 't-text-black', 'text-grey-5'],
  mvaStatusTextClassFont: ['', 'tuito-text-regular', 'tuito-text-semi-bold', 'tuito-text-semi-bold'],
  mvaStatusText: ['', 'Connecté', 'Déconnecté', 'Non installé']
}
