
export function SET_MVAS(state,mvas) {
  state.mvas = mvas
}
export function SET_GROUPED_MVAS(state,groupedMvas) {
  state.groupedMvas = groupedMvas
}

export function SET_LOCALIZATIONS(state, localizations) {
  state.localizations = localizations
}

export function SET_DEVICES(state, devices) {
  state.devices = devices
}

export function SET_DEVICEDRIVERS(state, devicedrivers) {
  state.devicedrivers = devicedrivers
}

export function SET_CALLS(state, calls) {
  state.calls = calls
}

export function ADD_CALL(state, call) {
  state.calls.push(call)
}

export function TAKE_CALL(state, call) {
  call.id_status = 3
  call.date_modified = new Date()
  console.log("TAKE_CALL", call)

}

export function RE_CALL(state, call) {
  call.id_status = 1
  call.date_created = new Date()
}

export function CLOSE_CALL(state, callObj) {
  const index = state.calls.findIndex(call => call.id == callObj.id);
  if (index > -1) {
    state.calls.splice(index,1); // 2nd parameter means remove one item only
  } else { console.error("NOT FOUND", callObj,state.calls) }
 // state.calls.remove(call)
}

export function ADD_WIFI (state, callObj) {
  const localPreregistredWifis = localStorage.getItem('PreregistredNets')
  const wifis = localPreregistredWifis ? JSON.parse(localPreregistredWifis) : []

  const index = wifis.findIndex(wifi => wifi.SSID === callObj.SSID)
  if (index >= 0) {
    wifis[index] = callObj
  } else {
    wifis.push(callObj)
  }
  localStorage.setItem('PreregistredNets', JSON.stringify(wifis))
}
