module.exports = {
  preMapName: '',
  appconfig: {
    apiServer: 'https://api-mivoco-online.prod.tuito.fr'
  },
  account: {
    apiUrl: 'https://api-mivoco-online.prod.tuito.fr',
    zone: 'dev'
  }
}
