export default {
  main_menu: {
    infrastructure_metas: { name: 'Démonstrateur en ligne' },
    dashboard: { label: 'Dashboard', caption: 'MVA browsing' },
    mva_install: { label: 'New MVA', caption: 'Install Wizard for a new MVA' },
    mctrl_install: { label: 'New Device', caption: 'Install new Device on a given MDA' },
    settings: { label: 'Settings', caption: 'Application settings and preferences' },
    notifications: { label: 'Notifications', caption: 'Browse all MVAs notifications' },
    remote: { label: 'Remote', caption: 'Command devices using remote' }
  },
  mva: {
    title: 'Assistants Vocaux',
    detail: {
      device_list: 'Devices List',
      new_device: 'New Device'
    },
    filters: {
      connected: 'Connecté',
      disconnected: 'Déconnecté',
      configurated: 'Configuré',
      notconfigurated: 'Non Configuré',
      mute: 'Mode confidentiel',
    }
   
  },
  calls: {
    title:'Appels en cours',
    list: 'Liste',
    map: 'Carte',
    zones: 'Zones',
    table: {
      call_created: 'Appel lancé :',
      area_name: 'Nom de la zone :',
      call_handled: 'Pris en charge :'
    },
    filters: {
      emergency: 'Appels urgents',
      normal: 'Appels non-urgents',
      handled: 'Appel pris en charge'
    },
    detail: {
      emergency: 'Appel urgent',
      normal: 'Appel non-urgent',
      handled: 'Appel pris en charge'
    },
    pagination: {
      previous: 'Précédent',
      next: 'Suivant'
    }
  },
  callLog: {
    title:'Historique des appels'
  },
  settings: {
    title: 'Paramètres',
    icon: 'o_settings',
    backToSetting: 'Retour aux paramètres',
    items: [
      {
        name: 'Appels',
        items: [
          {
            title: 'Liste des zones à alerter par défaut',
            icon: 'playlist_add_check'
          }
        ]
      },
      {
        title: 'Assistants vocaux',
        items: [
          {
            title: 'Modifier la configuration d\'un assistant vocal',
            icon: 'tune'
          },
          {
            title: 'Installer un nouvel assistant vocal',
            icon: 'downloading'
          },
          {
            title: 'Mettre à jour le pilote des assistants vocaux',
            icon: 'handyman'
          }
        ]
      }
    ]
  }
}
