import { DeviceApi, MvaApi, CallApi } from '../../generated/api.js'
import axios from 'axios'
import ServerApiConfig from '../../assets/ServerApiConfig.js'
import userService from './../../services/UserService';


export function loadAllMVAs({ commit, state }) {
  let api = new MvaApi(ServerApiConfig)
  const promise = new Promise((resolve, reject) => {
    api.getAllMVAs().then(result => {
      commit('SET_MVAS', result)
      resolve(result)
    }).catch(err => {
      reject(err)
    })
  })
  return promise
}

export function loadGroupedAllMVAs({ commit, state }, data) {
  console.debug(data.idSector)
  const promise = new Promise((resolve, reject) => {
    (new MvaApi(ServerApiConfig)).getGroupedAllMVAs(data.idSector, data.idLocalization, data.searchAny, data.isDisconnected, data.isConfidential).then(result => {
      commit('SET_GROUPED_MVAS', result)
      resolve(result)
    }).catch(err => {
      reject(err)
    })
  })
  return promise
}

export function loadAllLocalizations({ commit, state }) {
  let api = new MvaApi(ServerApiConfig);
  return axios.get(`${ServerApiConfig.basePath}/localization`).then(result => {
    commit('SET_LOCALIZATIONS', result.data)
  });
}

export function loadAllDevices({ commit, state }) {
  if (state.devices.length > 0) return;
  let api = new DeviceApi(ServerApiConfig);
  return api.getAllDevices().then(result => {
    commit('SET_DEVICES', result)

    // filling drivers list from device list
    // distinct drivers from list
    if (result) {
      let drivers = []
      result.forEach(elem => {
        if (!drivers.find(drv => drv?.id === elem.devicedriver?.id)) {
          drivers.push(elem.devicedriver)
        }
      })
      commit('SET_DEVICEDRIVERS', drivers)
    }
  });
}

export function loadAllCalls ({ commit, state }) {
  const api = new CallApi(ServerApiConfig)
  let username = userService.getInstance().getUser().username
  if((!username) || username === undefined){
    username = 'unknown'
  }
  return axios.get(`${ServerApiConfig.basePath}/activeCalls?username=${username}`).then(result => {
    console.debug(result.data)
    commit('SET_CALLS', result.data)
  })
    .catch((error) => {
      console.error(error)
    })
}

export function addCall({ commit, state }, callObj) {

  commit('ADD_CALL',
    {
      "id": callObj.id,
      "id_mva": callObj.id_mva,
      "date_created": new Date().toISOString(),
      "date_modified": new Date().toISOString(),
      "id_call_priority": callObj.id_call_priority,
      "id_status": callObj.id_status,
      "id_user_ack": callObj.id_user_ack,
      "callevents": []
    })
}

export function takeCall({ commit, state }, callObj) {
  commit('TAKE_CALL',
    callObj)
}

export function reCall({ commit, state }, callObj) {
  console.debug('recall', callObj)
  commit('RE_CALL',
    callObj)
}

export function closeCall({ commit, state }, callObj) {
  console.log("closeCall", state.calls.length)
  commit('CLOSE_CALL',
    callObj)
}

export function addWifiConf({ commit, state }, callObj) {
  console.debug("addWifiConf", state.wifis)
  commit('ADD_WIFI',
    callObj)
}
