
import { defineComponent } from 'vue'
import NotificationService from 'src/services/NotificationService'
import { Notify } from 'quasar'

export default defineComponent({
  name: 'App',
  async created () {
    // NotificationService.getInstance().InitSSE()
    NotificationService.getInstance().initVars(this.$router, Notify, this.$q)
    NotificationService.getInstance().initMobileSystemNotifications(this.$q)
  }
})
