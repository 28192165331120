export default function () {
  return {
    mvas: [],
    groupedMvas: {},
    devices: [],
    devicedrivers: [],
    calls: [],
    localizations: [],
    wifis: []
  }
}
