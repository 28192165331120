const env = process.env.NODE_ENV || 'developpement'

const config = {
  development: require('./developpement.js'),
  prod_aubagne: require('./prod_aubagne.js'),
  production: require('./production.js'),
  default: require('./environnement.js')
}

module.exports = { ...config.default, ...config[env] }
