import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import userService from '../services/UserService'
import ServerApiConfig from '../assets/ServerApiConfig.js'
import { DemoApi } from '../generated/api.js'
import NotificationService from '../services/NotificationService'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach(async (to, from, next) => {
    const auth = userService.getInstance()
    const isAuth = await auth.getIsAuthenticated()
    if ((to.path !== '/login') && (!isAuth)) {
      // return { path: '/login', query: { path: to.fullPath, queryParams: JSON.stringify(to.query) } }
      sessionStorage.setItem('navigateAfterLogin', { path: to.fullPath, queryParams: JSON.stringify(to.query) })
      console.debug('should go to ' + sessionStorage.getItem('navigateAfterLogin') + ' after login')
      next('/login')
    } else {
      if (to?.meta?.requiredScopes && to.meta.requiredScopes.length > 0) {
        if (userService.getInstance().getUser().scopes.split(' ').some(scope => to.meta.requiredScopes.includes(scope))) {
          next()
        } else {
          next('/com')
        }
      } else {
        next()
      }
      if (to.path !== '/login' && !sessionStorage.getItem('demoStarted')) {
        if (isAuth && userService.getInstance().getUser()?.isDemo) {
          sessionStorage.setItem('demoStarted', true)
          new DemoApi(ServerApiConfig).addDemoUserScenario().then((result) => {
            window.postMessage({ type: 'DemoUserDetected' })
            console.debug('demo user logged, history restarted, scenario will be played.')
          }).catch((err) => {
            console.error('demo user logged but history did not restart, scenario wont be played.', err)
          })
        }
      }
    }
    if (isAuth) {
      setTimeout(() => {
        NotificationService.getInstance().InitSSE()
      }, 500)
    }
  })

  return Router
})
