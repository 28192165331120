import Config from 'src/config/config.js'
import userService from './UserService';
import Helpers from 'src/declarations/Helpers'
import { ref, inject } from 'vue';
import { useQuasar, Notify } from 'quasar'

class NotificationService {

  private static instance: NotificationService;
  private numberOfVisibleNotifs = 0;
  private permission = false

  private quasar
  private router
  private source

  public notificationWorker: SharedWorker = null
  public notificationRegistration = null
  public notificationList = []
  public notificationsGrouped = {}

  static getInstance() {
    if (this.instance == null) {
      this.instance = new NotificationService();
    }
    return this.instance
  }

  public getFirstNotification(){
    let returnValue = null
    if(this.notificationList.length>0){
      returnValue = this.notificationList[0]
      this.notificationList = this.notificationList.slice(1)
    }
    return returnValue
  }

  public NotifyInfo(message) {
    this.notify.create({
      message: message,
      color: 'primary',
      icon: 'info'

    })
  }

  public showNotifNewMva (serial, id, router, screen) {
    this.numberOfVisibleNotifs++
    let actions : any[] = [
      { label: !(screen.xs || screen.sm)? 'Fermer' : '', color: 'negative1', icon:(screen.xs || screen.sm)?'close':'', outline: true, class: 'hover-font-negative2 q-mr-md'+((screen.xs || screen.sm)?' col-1':' q-notification-btn-costum col-1'), "no-caps": true},
      { label: !(screen.xs || screen.sm)? 'Installer' : '', rounded: !(screen.xs || screen.sm)? true : false, round: (screen.xs || screen.sm)? true : false, color: 'primary', class: 'q-mr-xl'+((screen.xs || screen.sm)?' col-3':' q-notification-btn-costum blue col-1'), flat: false, icon: 'downloading', handler: () => { router.push({ path: '/com/mva-install/' + id }) }, "no-caps": true }
    ]
    if(!(screen.xs || screen.sm)) actions.push({ label: '', class: 'q-mx-lg', disable: true })
    actions.push({ label: this.numberOfVisibleNotifs, rounded: true, color: 'notify', class: 'q-ml-xl tuito-notification-count', flat: false, icon: 'o_notifications', disable: true })
    this.notify.create({
      // message: "Nouvel assistant vocal prêt à être installé : SN "+notification_data.serial,
      message: "Nouvel assistant vocal prêt à être installé : SN "+serial,
      color: 'secondary',
      textColor: 'primary',
      timeout: 0,
      iconSize: 'md',
      icon: 'graphic_eq',
      iconColor: 'primary',
      classes: 'full-width costum-notification-style text-center items-center tuito-text-semi-bold',
      multiLine: false,
      progress: true,
      position: 'bottom',
      actions: actions,
      onDismiss: ()=>{this.numberOfVisibleNotifs--}
    })
    }

  public showErrorNotif(text,timeout, icon){
    this.numberOfVisibleNotifs++
    this.notify.create({
      message: text,
      color: 'negative',
      textColor: 'white',
      timeout: timeout,
      iconSize: 'md',
      icon: icon || null,
      iconColor: 'white',
      classes: 'full-width costum-notification-style text-center items-center tuito-text-semi-bold',
      multiLine: false,
      progress: true,
      position: 'bottom',
      priority: 1,
      sticky: true,
      led: { color: '#FF00FF', on: 500, off: 500 },
      actions: [
        { label: 'Fermer', color: 'white', outline: true, class: 'hover-font-negative2 col-1 q-notification-btn-costum q-mr-md', "no-caps": true},
        { label: '', class: 'q-mx-lg' },
        { label: this.numberOfVisibleNotifs, rounded: true, color: 'warning', class: 'q-ml-xl tuito-notification-count', flat: false, icon: 'o_notifications', disable: true }
      ],
      onDismiss: ()=>{this.numberOfVisibleNotifs--}
    })
  }

  private getTextColorFromNotifLevel(level){
    let textColor = 'primary'
    if(level){
      switch (level) {
        case 1:
          textColor = 'white'
          break;
        case 2:
          textColor = 'white'
          break;
        case 3:
          textColor = 'negative'
          break;
        case 4:
          textColor = 'white'
          break;
        default:
          break;
      }
    }
    return textColor
  }
  private getBgColorFromNotifLevel(level){
    let bgColor = 'secondary'
    if(level){
      switch (level) {
        case 1:
          bgColor = 'notify'
          break;
        case 2:
          bgColor = 'orange'
          break;
        case 3:
          bgColor = 'warning'
          break;
        case 4:
          bgColor = 'negative'
          break;
        default:
          break;
      }
    }
    return bgColor
  }

  public showCostumNotif (notification_data){
    console.debug(notification_data)
    if(notification_data.group && Object.keys(this.notificationsGrouped).includes(notification_data.group)){
      console.debug(this.notificationsGrouped)
      this.notificationsGrouped[notification_data.group].groupOfNotif()
      delete this.notificationsGrouped[notification_data.group]
    }
    this.numberOfVisibleNotifs++
    const bgColor = this.getBgColorFromNotifLevel(notification_data.level)
    const isBiggerThanSm = Helpers.isBiggerThanSm(this.quasar)
    const actions = [
      { label: 'Fermer', rounded: true, color: notification_data?.level>0 ? this.getTextColorFromNotifLevel(notification_data.level) : 'negative', class: 'hover-font-negative2 q-notification-btn-costum '+ (isBiggerThanSm ? ' t-text-25 q-mr-md' : ' t-text-15 q-mt-sm'), "no-caps": true},
      { label: '', class: isBiggerThanSm ? 'q-mx-lg' : 'q-mx-none q-px-none'},
      { label: this.numberOfVisibleNotifs, rounded: true, color: 'negative', class: 'q-ml-xl tuito-notification-count t-text-20 q-pa-none', flat: false, icon: 'o_notifications', disable: true }
    ]
    const notification = {
      group: false,
      message: notification_data.text + (notification_data.subText ? `.<br> Motif : ${notification_data.subText}` : ''),
      html: true,
      color: bgColor,
      textColor: this.getTextColorFromNotifLevel(notification_data.level),
      timeout: notification_data.timeout,
      iconSize: isBiggerThanSm? '7rem':'3rem',
      icon: notification_data.icon || null,
      iconColor: this.getTextColorFromNotifLevel(notification_data.level),
      classes: 'full-width costum-notification-style text-center items-center tuito-text-semi-bold '+(isBiggerThanSm? 't-text-30' : 't-text-20'),
      multiLine: !isBiggerThanSm,
      progress: true,
      position: 'bottom',
      priority: 1,
      sticky: true,
      led: { color: '#FF00FF', on: 500, off: 500 },
      actions: actions,
      onDismiss: ()=>{
        this.numberOfVisibleNotifs--
        if(notification_data.group){
          delete this.notificationsGrouped[notification_data.group]
          for (const notifName of Object.keys(this.notificationsGrouped)) {
            this.notificationsGrouped[notifName].groupOfNotif({actions: [
              this.notificationsGrouped[notifName].actions[0],
              this.notificationsGrouped[notifName].actions[1],
              { label: this.numberOfVisibleNotifs, rounded: true, color: 'negative', class: 'q-ml-xl tuito-notification-count t-text-20 q-pa-none', flat: false, icon: 'o_notifications', disable: true }
            ]})
          }
        }
      }
    }
    const groupOfNotif = this.quasar.notify(notification)
    if(notification_data.group){
      this.notificationsGrouped[notification_data.group] = {groupOfNotif, actions}
    }
    if(this.quasar.platform.is.electron){
      if(notification_data.audio && localStorage.getItem('muteAppSound')){
        let audioUrl = `${Config.appconfig.apiServer}/speech?utterance=${notification_data.audio.speechUtt}`
        if (notification_data.audio.speechRoom) audioUrl+=`&room=${notification_data.audio.speechRoom}`
        if (notification_data.audio.speechPost) audioUrl+=`&postutt=${notification_data.audio.speechPost}`
        console.debug(`will play sound ${audioUrl}`);
        (window as any).electron.playSound(audioUrl)
      }
      (window as any).electron.notify(notification_data)
    }
  }

  private notify

  public killNotificationWorker(){
    if(this.notificationWorker!=null){
      this.notificationWorker.port.close()
    }
  }

  public registerBrowserNotification(channels, closePrevious) {
    if(!("Notification" in window)){
      console.error('This browser does not support notifications.')
    }else{
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          this.permission = true
          if(!this.notificationRegistration){
            navigator.serviceWorker.getRegistrations().then(registrations => {
              let updated = false
              for (const registration of registrations) {
                if(registration.active?.scriptURL?.endsWith('sw.js')){
                  registration.update()
                  updated = true
                  console.debug('registration updated')
                }
              }
              if(!updated){
                navigator.serviceWorker.register("sw.js")
              }
              navigator.serviceWorker.ready.then((registration) => {
                this.notificationRegistration = registration
                if(closePrevious){
                  registration.active.postMessage({
                    type: 'close'
                  })
                }
                registration.active.postMessage({
                  type: 'register',
                  url: `${Config.appconfig.apiServer}/sse/register?sseId=${userService.getInstance().getUser().sseId}&username=${userService.getInstance().getUser().client.client}`,
                  channels,
                  locationOrigin: window.location.origin
                })
              });
            });
          }
        }
      })
    }
  }

  public initMobileSystemNotifications(q){
    console.debug('initMobileSystemNotifications on mobile system.')
  }

  public initVars(router, app, q){
    if(!this.quasar) {
      this.quasar = q
      this.router = router
      this.notify = app
    }
  }

  public killSSE() {
    this.source.close()
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        if(registration.active?.scriptURL?.endsWith('sw.js')){
          registration.active.postMessage({
            type: 'close'
          })
        }
      }
    })
  }

  public stopSSE() {
    if(this.source){
      this.source.close()
    }
  }

  public InitSSE() {
    if(this.source){
      this.source.close()
    }
    // const EventSource = NativeEventSource || EventSourcePolyfill;
    this.source = new EventSource(`${Config.appconfig.apiServer}/sse/register?sseId=${userService.getInstance().getUser().sseId}&username=${userService.getInstance().getUser().client.client}`)
    this.source.addEventListener("close", () => {
      console.log("SSE connection closed. Restarting...");
      // Code to restart the SSE connection goes here.
      this.InitSSE()
    });

    // global notifications
    this.source.addEventListener('Notification', (e) => this.processNotification(e, this.router, this.quasar) , false)
    console.debug(`will subscribe on notification `)
    
    let channels = []
    // SOS notifications
    if(userService.getInstance().getUser().can_take_sos){
      channels.push('SOS_Notification')
      this.source.addEventListener('SOS_Notification', (e) => this.processNotification(e, this.router, this.quasar) , false)
      console.debug(`will subscribe on SOS_Notification `)
    }
    // Medical assistant notifications
    if(userService.getInstance().getUser().can_take_med){
      channels.push('MED_Notification')
      this.source.addEventListener('MED_Notification', (e) => this.processNotification(e, this.router, this.quasar) , false)
      console.debug(`will subscribe on MED_Notification `)
    }
    // Personal assistant notifications
    if(userService.getInstance().getUser().can_take_assist){
      channels.push('ASSIST_Notification')
      this.source.addEventListener('ASSIST_Notification', (e) => this.processNotification(e, this.router, this.quasar) , false)
      console.debug(`will subscribe on ASSIST_Notification `)
    }
    // Technical notifications
    if(userService.getInstance().getUser().can_take_tech){
      channels.push('TECH_Notification')
      this.source.addEventListener('TECH_Notification', (e) => this.processNotification(e, this.router, this.quasar) , false)
      console.debug(`will subscribe on TECH_Notification `)
    }

      this.registerBrowserNotification(channels, true)
      
    
    this.source.addEventListener('open', function (e) {
      console.log( "source event Connected")
    }, false)

    this.source.addEventListener('error', function (e) {
      // console.error("error on source.")
      // console.error(e)
      const id_state = document.getElementById('state')
      if (e.eventPhase == EventSource.CLOSED){
        try {
          this.source.close()
        } catch (error) {
          // 
        }
        console.debug("SSE connection closed. Restarting...");
        // Code to restart the SSE connection goes here.
        NotificationService.getInstance().InitSSE()
      }
    
    }, false)
  }

  private processNotification(e, router, q) {
    // console.debug("event : Notification ")
    let notification_data = JSON.parse(eval('e.data'))
    // NotificationService.getInstance().notificationList.push(notification_data)
    // console.debug(notification_data)
    window.postMessage({type: 'Notification'})

    if (notification_data.key == 'CUSTOM') {
      NotificationService.getInstance().showCostumNotif(notification_data)
    }
    if (notification_data.key == 'ERROR') {
      NotificationService.getInstance().showErrorNotif(notification_data.text,notification_data.timeout || 5000, notification_data.icon)
    }
    if (notification_data.key == "new_mva") {
      //SEND NOTIFICATION
      NotificationService.getInstance().showNotifNewMva(notification_data.payload.serial,notification_data.payload.id,router,q.screen)
    }
  }

  public initMobileNotification (q) {
    // if(q.platform.is.capacitor){
    //   setInterval(() => {
    //     (cordova as any).plugins.notification.local.requestPermission((granted)=> {console.debug(granted)});
  
    //     (cordova as any).plugins.notification.local.on('takeCall', ()=>{console.debug('call taken')});
  
    //     (cordova as any).plugins.notification.local.schedule({
    //       title: 'Un appel d\'urgence est lancé',
    //       text: 'Pouvez vous le prendre en charge ?',
    //       // attachments: ['file://img/rb-leipzig.jpg'],
    //       vibrate: true,
    //       sound: true,
    //       foreground: true,
    //       wakeup: false,
    //       actions: [
    //           { id: 'takeCall', title: 'Prendre en charger', launch: true },
    //           { id: 'no',  title: 'Ignorer', launch: false }
    //       ]
    //     })
    //   }, 5000);
    // }
  }
}

function successHandler (result) {
	console.log('result = ' + result);
  
}
function errorHandler (error) {
	console.log('error = ' + error);
}
export default NotificationService
