
const routes = [
  {
    path: '/login',
    component: () => import('pages/login.vue')
  },
  {
    path: '/',
    redirect: to => { return '/com' }
  },
  {
    path: '/com',
    component: () => import('layouts/ComLayout.vue'),
    children: [
      { path: '', component: () => import('pages/mivocom/CallsInProgress.vue'), meta: { requiredScopes: ['MADMIN', 'FADMIN', 'TADMIN', 'INFIRM', 'TECH'] } },
      { path: 'inprogress', component: () => import('pages/mivocom/CallsInProgress.vue'), meta: { requiredScopes: ['MADMIN', 'FADMIN', 'TADMIN', 'INFIRM', 'TECH'] } },
      { path: 'logs', component: () => import('pages/mivocom/CallLog.vue'), meta: { requiredScopes: ['MADMIN', 'FADMIN'] } },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('pages/mivocom/Notifications.vue'),
        meta: { requiredScopes: ['MADMIN', 'FADMIN', 'TADMIN'] }
      },
      {
        path: 'kpis',
        name: 'KPIs',
        component: () => import('pages/mivocom/KPIs.vue'),
        meta: { requiredScopes: ['MADMIN', 'FADMIN', 'INFIRM', 'TECH'] }
      },
      {
        path: 'settings/users-settings',
        component: () => import('pages/mivocom/SettingUsers.vue')
        // La gestion de cette page est faite par tab et non pas par requiredScopes
      },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
