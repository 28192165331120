import userService from '../../services/UserService.js'
import encrypt from '../../util/encrypt'

let us = userService.getInstance()

export function login ({ dispatch, commit }, { username, password, query }) {
  us.login(username, password).then(
    user => {
      us.isAuthenticated = true
      console.debug('login succes', userService.getInstance().getIsAuthenticated())
      encrypt.encryptCredentials(username,password)
      commit('loginSuccess', user)
      this.$router.push({ path: query?.path ? query.path : '/', query: query?.queryParams ? JSON.parse(query.queryParams) : {} })
    },
    error => {
      us.isAuthenticated = false
      console.debug('loginFailure', error)
      commit('loginFailure', error)
      // dispatch('alert/error', error, { root: true })
    }
  )
}

export function logout ({ commit }) {
  us.logout()
  commit('logout')
}

export function register ({ dispatch, commit }, params) {
  commit('registerRequest', params)
  const newUser = params
  newUser.password = btoa(newUser.password)
  delete newUser.passwordConfirm
  newUser.token = this.us.token
  this.us.register(newUser)
    .then(
      user => {
        commit('registerSuccess', user)
        this.$router.push('/login')
        setTimeout(() => {
          // display success message after route change completes
          dispatch('alert/success', 'Registration successful', { root: true })
        })
      },
      error => {
        commit('registerFailure', error)
        dispatch('alert/error', error, { root: true })
      }
    )
}

export function getAll({ commit }) {
  commit('getAllRequest');

  us.getAll()
    .then(
      users => commit('getAllSuccess', users),
      error => commit('getAllFailure', error)
    )
}

export function _delete({ commit }, id) {
  commit('deleteRequest', id)

  us._delete(id)
    .then(
      user => commit('deleteSuccess', id),
      error => commit('deleteFailure', { id, error: error.toString() })
    )
}