import Config from 'src/config/config.js'
import NotificationService from './NotificationService'
import { watch, reactive } from 'vue';

class userService {

  private static instance: userService
  private numberOfVisibleNotifs = 0
  public isAuthenticated = reactive({value : false})
  public token = null
  public humanScopes = [
    { label: 'Admin solution', value: 'MADMIN' },
    { label: 'Admin fonctionnel', value: 'FADMIN' },
    { label: 'Admin technique', value: 'TADMIN' },
    { label: 'Infirmier', value: 'INFIRM' },
    { label: 'Technicien', value: 'TECH' }
  ]

  static getInstance() {
    if (this.instance == null) {
      this.instance = new userService()
    }
    return this.instance
  }

  constructor() {
    watch(
      () => this.isAuthenticated.value,
      (newValue: any, oldValue: any) => {
        if (oldValue == false && newValue == true) {
          console.debug('Will start NotificationService')
          NotificationService.getInstance().InitSSE()
        }else if (newValue == false) {
          NotificationService.getInstance().killSSE()
        }
      }
    )
  }

  public authHeader () {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
      return { Authorization: 'Bearer ' + user.token }
    } else {
      return {}
    }
  }

  private generateUUID(username) {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `SUPERVISOR-UI-${s4()}${s4()}${s4()}-${username}`;
  }

  public login (username, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password: btoa(password), zone: Config.account.zone })
    }
    console.debug(requestOptions)

    return fetch(`${Config.account.apiUrl}/auth/authenticate`, requestOptions)
      .then(this.handleResponse)
      .then(user => {
        // login successful if there's a jwt token in the response
        console.debug(user)
        if (user.token) {
          user.sseId = this.generateUUID(user.username)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(user))
          this.token = user.token
          if(!NotificationService.getInstance().notificationWorker){
            const channels = this.getChannels()
            if(channels.length>0){
              NotificationService.getInstance().registerBrowserNotification(channels, true)
            }
          }
          console.debug('authentification success')
          this.isAuthenticated = reactive({value : true})
        }
        return user
      })
  }

  public getChannels() {
    this.getUser()
    
    let channels = []
    // SOS notifications
    if(this.getUser().can_take_sos){
      channels.push('SOS_Notification')
    }
    // Medical assistant notifications
    if(this.getUser().can_take_med){
      channels.push('MED_Notification')
    }
    // Technical notifications
    if(this.getUser().can_take_tech){
      channels.push('TECH_Notification')
    }
    return channels
  }

  public logout () {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
    sessionStorage.removeItem('demoStarted')
    console.debug("loginOut")
    this.token = null
    this.isAuthenticated = reactive({value : false})
    if(NotificationService.getInstance().notificationWorker){
      NotificationService.getInstance().notificationWorker.port.close()
      NotificationService.getInstance().notificationWorker = null
    }
    NotificationService.getInstance().stopSSE()
    window.history.go()
  }

  public register (user) {
    user.password = btoa(user.password)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    }

    return fetch(`${Config.account.apiUrl}/auth/register`, requestOptions).then(this.handleResponse)
  }

  public getAll () {
    const requestOptions = {
      method: 'GET',
      headers: this.authHeader()
    }

    return fetch(`${Config.account.apiUrl}/auth/all`, requestOptions).then(this.handleResponse)
  }

  public getById (id) {
    const requestOptions = {
      method: 'GET',
      headers: this.authHeader()
    }

    return fetch(`${Config.account.apiUrl}/users/${id}`, requestOptions).then(this.handleResponse)
  }

  public update (user) {
    const requestOptions = {
      method: 'PUT',
      headers: { ...this.authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    }

    return fetch(`${Config.account.apiUrl}/users/${user.id}`, requestOptions).then(this.handleResponse)
  }

  public updatefromAdmin (user) {
    const requestOptions = {
      method: 'PUT',
      headers: { ...this.authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    }

    return fetch(`${Config.account.apiUrl}/users/updatefromAdmin/${user.id}`, requestOptions).then(this.handleResponse)
  }

  public search (username,firstname,lastname,scopes,can_take_sos,can_take_med,can_take_assist,can_take_tech) {
    const requestOptions = {
      method: 'GET',
      headers: { ...this.authHeader()}
    }
    const where = {}
    if(username!=null && username!=undefined)  (where as any).username=username
    if(firstname!=null && firstname!=undefined)  (where as any).firstname=firstname
    if(lastname!=null && lastname!=undefined)  (where as any).lastname=lastname
    if(scopes!=null && scopes!=undefined && scopes.length>0)  (where as any).scopes=scopes.map(x => x.value)
    can_take_sos ? (where as any).can_take_sos=[0,1] : (where as any).can_take_sos=[0]
    can_take_med ? (where as any).can_take_med=[0,1] : (where as any).can_take_med=[0]
    can_take_assist ? (where as any).can_take_assist=[0,1] : (where as any).can_take_assist=[0]
    can_take_tech ? (where as any).can_take_tech=[0,1] : (where as any).can_take_tech=[0]
    return fetch(`${Config.account.apiUrl}/users/search?`+ new URLSearchParams(where), requestOptions).then(this.handleResponse)
  }

  // prefixed function name with underscore because delete is a reserved word in javascript
  public _delete(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: this.authHeader()
    }

    return fetch(`${Config.account.apiUrl}/users/${id}`, requestOptions).then(this.handleResponse)
  }

  public handleResponse (response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401 && this.isAuthenticated.value) {
          // auto logout if 401 response returned from api
          this.logout()
          location.reload()
        }
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }

      return data
    })
  }

  public async getIsAuthenticated () {
    if(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token){
      const requestOptions = {
        method: 'POST',
        headers: { ...this.authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({token: JSON.parse(localStorage.getItem('user')).token})
      }
      const resVerifiedToken = await fetch(`${Config.account.apiUrl}/auth/verify`, requestOptions)
      const verifiedToken = await resVerifiedToken.json()
      this.isAuthenticated = reactive({value: verifiedToken?.valid})
      if(this.isAuthenticated.value){
        this.token = JSON.parse(localStorage.getItem('user')).token
      }
    }else{
      this.isAuthenticated = reactive({value : false})
    }
    
    return this.isAuthenticated.value;
  }

  public getUser () {
    return JSON.parse(localStorage.getItem('user'))
  }

  public isAdmin() {
    return JSON.parse(localStorage.getItem('user')).scopes.split(' ').includes('MADMIN')
  }

  public canChangeUser() {
    return JSON.parse(localStorage.getItem('user')).scopes.split(' ').includes('MADMIN')
  }

  public getHumanScopes() {
    if(!this.isAuthenticated.value){
      return null
    } else {
      return JSON.parse(localStorage.getItem('user')).scopes
                      .split(' ')
                      .filter(s => this.humanScopes.map(x => x.value).includes(s))
                      .map(s => this.humanScopes.filter(x => x.value === s)[0]?.label)
                      .join(",")
    }
  }
}

export default userService
